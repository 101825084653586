<template>
  <v-app class="pt-15">
    <customize-header homeRouter="/OnlineRetailersStore/Home"
                      homeTitle="电商商城"
                      :links="links"></customize-header>
    <router-view></router-view>
    <customize-suspendBtn></customize-suspendBtn>
    <customize-footer></customize-footer>
  </v-app>
</template>

<script>

export default {
  name: 'OnlineRetailersStore',
  data: () => ({
    links: [
      {
        title: "首页",
        router: "/OnlineRetailersStore/Home",
        children: []
      },
      {
        title: "解决方案",
        router: "/OnlineRetailersStore/Solution",
        children: []
      },
      {
        title: "口碑案例",
        router: "/OnlineRetailersStore/Case",
        children: []
      }
    ],
  }),
};
</script>
<style lang="scss" scoped>

</style>